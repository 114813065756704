<template>
    <div class="color-back" :class="backColor">
        <div class="ppal-container">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ColorBackground',
    props:  {
        backColor: {
            type: String,
        },
    }
}
</script>

<style scoped>
.ppal-container{
    max-width: 1500px;
    margin: auto;
}
.white{
    background-color: var(--white);
}
.dark-violet{
    background-color: var(--dark-violet);
}
.color-back{
    position: relative;
    z-index: 5;
    width: 100vw;
    right: 30px;
    padding: 0 30px;
}
/* RESPONSIVE */
@media (min-width:768px) {
    .color-back{
        right: 45px;
        padding: 0 45px;
    }
}

@media (min-width:992px) {
    .color-back{
        right: 75px;
        padding: 0 75px;
    }
}
</style>