<template>
    <main>
        <SectionIntroTitle 
        sectionName="Dolor Crónico"
        alignCenter="true"
        ppalText="Dolor crónico"
        secondText="y medicina del dolor."
        />
        <p class="description col-12 col-lg-10 col-xl-7"></p>
        <WhiteBackground backColor="white">
            <div class="image">
                <picture>
                    <source :srcset=KinesiologyWebp type="image/webp">
                    <source :srcset=KinesiologyJpg type="image/jpeg" />
                    <source :srcset=KinesiologyPng type="image/png">
                    <img class="col-11" :src=KinesiologyWebp alt="Dolor" />
                </picture>
            </div>
            <div class="intro-text">
                <div>
                    <p class="subtitle"><span class="secondText">Procedimientos</span>  Médicos.</p> 
                </div>
                <div class="text">
                    <p class="bolder">
                        Disponemos de alternativas y procedimientos médicos para el tratamiento de dolor crónico.                     </p>
                    <p>
                        Nuestros médicos especialistas en dolor crónico aplican las últimas técnicas y terapias que permiten a nuestros pacientes reducir, controlar e incluso eliminar el dolor crónico que padecen.
                    </p>
                    <p>
                        Las técnicas médicas que entregamos a nuestros pacientes van desde terapias de medicina regenerativa como infiltración con células madre y plasma rico en plaquetas, hasta bloqueos nerviosos y aplicación de radiofrecuencia.
                    </p>
                    <p>
                        Creemos que hay 2 formas de atender a nuestros pacientes: Una forma es en un espacio tranquilo con vista al mar en donde puedes recibir un café, disfrutar de la música y los aromas, con una atención cariñosa, en instalaciones modernas y sala de procedimiento aprobadas por la autoridad sanitaria, con una unidad científica y traumatológica de apoyo y seguimiento... Y la otra forma no es nuestra forma :)
                        
                    </p>
                </div>
            </div>
            <picture>
                <source :srcset=CurveUpWebp type="image/webp">
                <source :srcset=CurveUpSvg type="image/svg+xml" />
                <source :srcset=CurveUpPng type="image/png">
                <img class="curve" :src=TraumatologyWebp alt="Curva decorativa" />
            </picture>

            <ProceduresSection :decorationCurve=false :background=true />

            <SectionName text="Patologías tratables" class="d-flex justify-content-center pt-5 pb-2" />
            <section id="carousel-columns" class="col-12 col-xl-11 pb-5">
                <template v-for="item in pathologyItems" :key="item.id">
                    <CarouselColum
                    :id='item.id'
                    :pathology='item.pathology'
                    :description='item.description'
                    :srcWebp='item.srcWebp'
                    :srcPng='item.srcPng'
                    :srcJpg='item.srcJpg'
                    :altImg='item.altImg'
                    :active="item.active"
                    @beforeClick="showBefore(showNumber)"
                    @nextClick="showNext(showNumber)"
                    @cardClick="toggleActive(item.id)"
                    link="/patologias"
                    />
                </template>
            </section>
        </WhiteBackground>
    </main>
    <FooterBar />
</template>

<script>
import SectionIntroTitle from '@/components/SectionIntroTitle.vue';
import WhiteBackground from '@/components/WhiteBackground.vue';
import FooterBar from '../BasicComponents/FooterBar.vue';
/* import SectionTreatments from '../HomePage/SectionTreatments.vue'; */
import CarouselColum from '@/components/CarouselColum.vue';
import SectionName from '@/components/SectionName.vue';
import PathologyInfo from '../TraumatologyPage/pathologyInfo.js';
import ProceduresSection from './ProceduresSection.vue';

import KinesiologyPng from '../../assets/img/photos/png/Rodilla7.png';
import KinesiologyJpg from '../../assets/img/photos/jpg/Rodilla7.jpg';
import KinesiologyWebp from '../../assets/img/photos/webp/Rodilla7.webp';

import CurveUpSvg from '../../assets/img/decoration/curve-up.svg';
import CurveUpPng from '../../assets/img/decoration/curve-up.png';
import CurveUpWebp from '../../assets/img/decoration/curve-up.webp';


export default {
    name: 'DolorPage',
    components: {
        SectionIntroTitle,
        WhiteBackground,
        FooterBar,
        /* SectionTreatments, */
        ProceduresSection,
        CarouselColum,
        SectionName,
    },
    props:  {

    },
    data() {
        return {
            KinesiologyPng,
            KinesiologyJpg,
            KinesiologyWebp,
            CurveUpSvg,
            CurveUpWebp,
            CurveUpPng,
            PathologyInfo,
            showNumber: 1,
        }
    },
    computed: {
        pathologyItems() {
            return this.PathologyInfo.slice();
        },
    },
    methods: {
        toggleActive(selectedItem) {
            let sections = Array.from(document.getElementsByClassName("section"));
            sections.forEach(element => {
                element.classList.remove('active');
            });
            sections[selectedItem - 1 ].classList.add('active');
            this.showNumber = selectedItem;
        },
        showBefore(selectedItem) {
            let sections = Array.from(document.getElementsByClassName("section"));
            sections.forEach(element => {
                element.classList.remove('active');
            });
            sections[selectedItem - 1 ].classList.add('active');
            this.showNumber = this.showNumber - 1;
        },
        showNext(selectedItem) {          
            let sections = Array.from(document.getElementsByClassName("section"));
            sections.forEach(element => {
                element.classList.remove('active');
            });
            sections[selectedItem].classList.add('active');
            this.showNumber = this.showNumber + 1;
        },
    },
}
</script>

<style scoped>
.description{
    text-align: center;
    margin: 2rem auto 10rem auto;
    position: relative;
    z-index: 2;
}
.image{
    position: relative;
    top: -7.5rem;
    margin-bottom: -7.5rem;    
}
.image img{
    min-height: 250px;
    object-fit: cover;
    border-radius: 1rem;
    display: flex;
    margin: auto;
}
.intro-text{
    padding: 5rem 0;
    position: relative;
    z-index: 2;
}
.subtitle{
    color: var(--light-violet);
    font-size: 40px;
    line-height: 45px;
    font-weight: 500;
    letter-spacing: 0px;
    margin-bottom: 2rem;
}
.secondText{
    color: var(--dark-violet);
}
.bolder{
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.6rem;
}
.curve{
    position: relative;
    margin-bottom: -580px ;
    top: -580px ;
    max-width: 100%;
}

#carousel-columns{
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: auto;    
}

/* RESPONSIVE */
@media (min-width:576px) {
    .subtitle{
        font-size: 50px;
        line-height: 55px;
        max-width: 80%;
    }
    .text{
        padding-left: 20%;
    }
}

@media (min-width:768px) {
    .curve{
        left: 5%;
    }
}

@media (min-width:992px) {
    #carousel-columns{
        flex-direction: row;
    }
}

@media (min-width:1200px) {
    .subtitle{
        font-size: 60px;
        line-height: 62px;
        max-width: 50%;
    }
    .text{
        padding-left: 42%;
        padding-top: 0.5rem;
    }
    .curve{
        max-width: 390px;
        margin-bottom: -600px;
        top: -600px;
    }
}

@media (min-width:1400px) {}
</style>