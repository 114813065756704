<template>
  <div class="nav-bar">
    <NavBar />
  </div>
  <router-view />
</template>

<script>
import NavBar from './views/BasicComponents/NavBar.vue';

export default {
  name: 'App',
  components: {
    NavBar,
  }
}
</script>

<style scoped>
.nav-bar{
  position: sticky;
  top: 0;
  z-index: 15;
}

/* RESPONSIVE */
@media (min-width:768px) {
    .nav-bar{
        width: calc(100% + 8px);
    }
}

</style>
