/* IMAGES */
/* import CellPng from '../../assets/img/photos/png/cell.png';
import CellJpg from '../../assets/img/photos/jpg/cell.jpg';
import CellWebp from '../../assets/img/photos/webp/cell.webp'; */

import Cell2Webp from '../../assets/img/blog/webp/cell2.webp';
import Cell2Webp320 from '../../assets/img/blog/webp/cell2_320.webp';
import Cell2Webp480 from '../../assets/img/blog/webp/cell2_480.webp';
import Cell2Webp768 from '../../assets/img/blog/webp/cell2_768.webp';
import Cell2Webp1080 from '../../assets/img/blog/webp/cell2_1080.webp';

/*import KneeWebp from '../../assets/img/blog/webp/knee.webp';
import KneeWebp320 from '../../assets/img/blog/webp/knee_320.webp';
import KneeWebp480 from '../../assets/img/blog/webp/knee_480.webp';
import KneeWebp768 from '../../assets/img/blog/webp/knee_768.webp';
import KneeWebp1080 from '../../assets/img/blog/webp/knee_1080.webp';
*/
import CellWebp from '../../assets/img/blog/webp/cell.webp';
import CellWebp320 from '../../assets/img/blog/webp/cell_320.webp';
import CellWebp480 from '../../assets/img/blog/webp/cell_480.webp';
import CellWebp768 from '../../assets/img/blog/webp/cell_768.webp';
import CellWebp1080 from '../../assets/img/blog/webp/cell_1080.webp';

/* BLOG INFO */

let blog = [
    {
        id: 'celulas_madre',
        classSection: "stem-cells",
        section: "Células madre", 
        title: "¿Qué son las células madre y para qué sirven?",
        subtitle: "Las células madre mesenquimales son un tipo de células madre adultas que se encuentran en varios tejidos del cuerpo, como la médula ósea, el tejido adiposo y el tejido conjuntivo. Estas células tienen la capacidad de diferenciarse en varios tipos de células del cuerpo, como osteoblastos, adipocitos y miocitos.",
        description: "Las células madre son células que tienen la capacidad de dividirse y dar lugar a diferentes tipos de células especializadas.",
        srcWebp: Cell2Webp,
        srcWebp320: Cell2Webp320,
        srcWebp480: Cell2Webp480,
        srcWebp768: Cell2Webp768,
        srcWebp1080: Cell2Webp1080,
        altImg: "Células madre", 
        date: '13 de enero de 2023',
        author: 'Cinthia Briceño Rosas',
        fullText: `
            Las células madre mesenquimales son células que se encuentran en nuestro cuerpo durante toda nuestra vida y que se caracterizan por dos propiedades fundamentales: su capacidad de auto renovación, es decir, de dividirse para formar una copia de sí misma, y su potencial de diferenciación, es decir, de poder convertirse en distintos tipos de células de nuestro organismo. \n
            Su función es conservar el equilibrio fisiológico, manteniendo la estructura y función de los tejidos. Las utilizamos a diario para reemplazar células o tejidos que se han dañado o desgastado, actuando como un sistema reparador. Sin embargo, conforme envejecemos, va disminuyendo su calidad y cantidad, motivo por el cual, resulta beneficioso administrar células de alto potencial. \n
            El tratamiento combinado para lesiones de cartílago implementado por Clínica Novocel incluye 2 elementos fundamentales: células madre mesenquimales derivadas de tejido de cordón umbilical (células con el más alto potencial clínico, con propiedades anti-inflamatorias, inmunomoduladoras y regenerativas) y ácido hialurónico compatible biológicamente, producto que no sólo actúa como amortiguador y lubricante de la articulación, sino que también favorece la sobrevida y actividad de las células madre. \n
            El objetivo del tratamiento combinado de células madre y ácido hialurónico es que el paciente disminuya los signos artrósicos, aumente su funcionalidad articular y recupere movilidad. Se estimula la capacidad regenerativa del organismo para revertir los mecanismos patológicos que conllevan al desgaste de cartílago, logrando disminuir el dolor y la inflamación, al mismo tiempo que se propicia la regeneración de cartílago articular, manteniendo los beneficios clínicos por un amplio periodo de tiempo.
        `
    },
    /*
    {
        id: 2,
        classSection: "kinesiology",
        section: "Kinesiología", 
        title: "¿Qué prefieren los pacientes?",
        subtitle: "Estos son los 3 atributos más importantes en kinesiología. ¿Qué opinan los pacientes acerca de la kinesiología? ¿Qué atributo es más valioso para ellos? ¿Qué destacan más los pacientes? Nos hicimos estas preguntas y fuimos recabando percepciones que hoy queremos compartir.",
        description: "¿Qué opinan los pacientes acerca de la kinesiología? ¿Qué atributo es más valioso para ellos? ¿Qué destacan más los pacientes?",
        srcWebp: KneeWebp,
        srcWebp320: KneeWebp320,
        srcWebp480: KneeWebp480,
        srcWebp768: KneeWebp768,
        srcWebp1080: KneeWebp1080,    
        altImg: "Medicina ", 
        date: '18 de junio de 2023',
        author: 'Claudio Carreño',
        fullText: `
            La kinesiología es fundamental para la rehabilitación y recuperación de la movilidad del paciente. A través de terapias y ejercicios específicos, los kinesiólogos nos ayudan a recuperar la funcionalidad y mejorar nuestra calidad de vida. \n
            Los kinesiólogos emplean técnicas y ejercicios para aliviar el dolor muscular y articular, identificando las causas que originan el dolor y desarrollando planes de tratamiento para mitigar o eliminar el dolor. \n
            En los últimos 4 meses en Clínica Novocel hemos atendido a más de 120 pacientes en nuestra kinesiología. 120 pacientes puede parecer un número alto o pequeño, dependiendo de la perspectiva y del tipo de servicio que se entregue. Si la atención es personalizada y la Clínica puede ayudar para que no existan más de 3 a 4 pacientes por sesión, asegurando una atención dedicada por el especialista, entonces 120 pacientes puede ser un número importante. En otros lugares, los pacientes comparten el espacio con 8 a 10 pacientes, perdiendo la personalización y la calidad de atención. En ese caso 120 pacientes puede ser un número pequeño. Dependerá, como ya dijimos, de la perspectiva. \n
            En general, un paciente realiza un plan de 10 sesiones de 1 hora de duración combinadas entre fisioterapia y kinesiología, dependiendo de lo que determine el especialista traumatólogo y la evaluación kinesiológica. En Clínica Novocel, la atención es personalizada y no habrán más de 3 a 4 pacientes por bloque, lo que permite hacer seguimiento del paciente y posibilitar una relación cercana con el profesional. \n
            Ya... pero volvamos al foco. ¿En qué estábamos? Ah, ya recuerdo. ¿Qué opinan los pacientes acerca de la kinesiología? Lo primero que destacan es la calidad del profesional. Nuestro kinesiólogo Hans Cuevas además de ser un profesional muy capacitado y con una habilidad envidiable, es un "amor", un "caballero", un "crac", dirán algunos pacientes. Lo que más comentan los pacientes tiene relación con la cercanía y cordialidad de Hans. No hay que decirle esto a Hans si por favor, es mejor que quede entre nosotros no más =) \n
            Junto con lo anterior, los pacientes destacan la atención personalizada. El hecho de que no existan más de 3 a 4 pacientes por sesión ha sido un atributo que los pacientes destacan cada vez que pueden. Los pacientes se sienten con más privacidad y se sienten más atendidos en términos profesionales, pues el kinesiólogo está junto con ellos de manera personalizada. Es muy divertido porque los pacientes le llevan regalos a Hans... yo creo que es hora de que compartas los regalos Hans! \n
            El último punto que destacan nuestros pacientes tiene relación con la vista al mar. Clínica Novocel está ubicada 12 Norte 785 oficina 1003-1002 en el edificio pamplona, a 1 cuadra de calle Libertad en Viña del Mar, en el piso 10. Una vista al mar envidiable. Una vez que los pacientes terminan sus sesiones, les damos algunos minutos en nuestra sala de recovery desde la cual pueden disfrutar de una vista hermosa lo que los ayuda a relajarse y seguir su día con otra vibra. Algunos pacientes terminan su plan de 10 sesiones y nos piden 10 sesiones más pues sienten que en la kinesiología tienen un espacio personal para ejercitarse y llevar una vida saludable. \n
            Entonces, ¿qué destacan más los pacientes? Las cordialidad del profesional, la atención personalizada, y el espacio con vista al mar. Así que ya sabes, en Clínica Novocel puedes encontrar todo esto. Cuando tengas la necesidad de realizar terapias kinesiológicas por algún motivo, ¿ya sabes qué preferirías tú?
        `
    },
    */
    {
        id: 'infiltracion',
        classSection: "stem-cells",
        section: "Células madre", 
        title: "¿Qué pasa después de la infiltración?",
        subtitle: "Muchos pacientes tienen dudas acerca de qué pasa despúes del procedimiento. Esto es lo que puedes esperar después de un procedimiento de infiltración intrarticular con células madre.",
        description: "Después de la infiltración con células madre viene la recuperación y rehabilitación. Conoce aquí lo que ocurre luego del procedimiento.",
        srcWebp: CellWebp,
        srcWebp320: CellWebp320,
        srcWebp480: CellWebp480,
        srcWebp768: CellWebp768,
        srcWebp1080: CellWebp1080,
        altImg: "Rodilla en recuperación", 
        date: '27 de agosto de 2023',
        author: 'Cinthia Briceño Rosas',
        fullText: `
            Hasta la fecha, en Clínica Novocel, hemos tratado a más de 150 pacientes con la terapia traumatológica de infiltración intrarticular de células madre y ácido hialurónico, como terapia combinada de medicina regenerativa. A todos nuestros pacientes les entregamos, desde nuestra consulta científica, los conceptos biológicos del procedimiento, les explicamos los principios y qué esperar después del procedimiento. \n
            Sin embargo, la pregunta sigue presente... ¿Qué esperar despúes del procedimiento con células madre? \n
            Despúes de la infiltración que realiza nuestro traumatólogo en nuestra sala de procedimiento autorizada, llega el momento de volver a casa. Sabrás con anticipación que debes evitar hacer fuerza y movimientos bruscos, por lo que seguramente estarás acompañado por alguien cercano a quien posiblemente le debas un café por haberte esperado :) No te preocupes... nosotros ya le habremos ofrecido un café, así que no debiera cobrarte nada. \n
            Te irás a casa y te entregaremos una compresa fría para que apliques en la zona de la infiltración. Probablemente sientas un poco de dolor debido a que hemos agregado en tu articulación un volumen que antes no existía, así que sentirás cierta inflamación en la zona intervenida. Para tratar esta inflamación, el traumatólogo te entregará una orden médica para que puedas adquirir analgésicos que te ayudarán en caso de dolor. Es super importante suspender los medicamentos que te indiquen suspender, y respetar las dosis indicadas. \n
            Luego, vienen 2 días de recuperación. Durante estos 2 días debes evitar hacer movimientos bruscos, y sobre todo seguir la recomendación médica que recibirás, como no consumir ciertos medicamentos y empezar tu terapia kinesiológica. Puedes sentir dolor, o puede ser que no sientas dolor. En cualquier caso, siempre tendrás los medicamentos de apoyo y el teléfono directo de nuestra directora científica quien estará disponible por cualquier duda. Recuerda no hacer movimientos bruscos por 2 días, esto incluye: evitar subir y bajar escaleras, no trotar ni correr, y no bailar. Aunque si suena un rock & roll deberás apagar la música lo más pronto posible para evitar que tu cuerpo se apodere de ti y se ponga a bailar solo =) \n
            Ya entrarás a la etapa de recuperación después de los primeros días. La zona intervenida comenzará a desinflamarse y podrás retomar tus actividades. Es muy importante iniciar tus terapias kinesiológicas lo más pronto posible para rehabilitarte debidamente. Recuerda que en Novocel puedes tomar tus sesiones de kinesiología. \n
            Recibirás un llamado de nuestra directora científica para saber cómo ha evolucionado todo dentro de las primeras semanas. \n
            Transcurrido 1 mes después de la intervención, será el momento de tu control. Si todo va bien, nuestro equipo médico te dará el alta médica. Podrás ayudarnos con un testimonio el día de tu control así podamos ayudar juntos para que otros pacientes conozcan estas terapias. Seguiremos en contacto contigo en los meses sucesivos para registrar que todo vaya bien. \n
            ¿Qué pasa entonces después del procedimiento con células madre? Súper fácil: Aplicar compresas de frío, consumir los medicamentos indicados solo en caso de dolor o inflamación, evitar ciertos medicamentos que te hayamos indicado según tu caso, realizar reposo los 2 primeros días, iniciar tu proceso de rehabilitación, y asistir a tu control para evaluar tu evolución. \n
            ¡Nos vemos en Clínica Novocel!
        `
    },
    /*{
        id: 4,
        classSection: "hyaluronic-acid",
        section: "Ácido Hialurónico", 
        title: "Ejemplo ácido hialurónico",
        subtitle: "Ejemplo de subtitulo",
        description: "Últimas noticias se ven primero. Ejemplo ácido hialurónico",
        srcWebp: CellWebp,
        srcPng: CellPng,
        srcJpg: CellJpg, 
        altImg: "Células madre", 
        date: '',
        author: '',
        fullText: ``,
    }, */
        /* {
        id: 5,
        classSection: "regenerative-medicine",
        section: "Medicina regenerativa", 
        title: "¿Qué es la medicina regenerativa?",
        subtitle: "Ejemplo de subtitulo",
        description: "La medicina regenerativa es un campo de la medicina que se centra en utilizar terapias para regenerar o reparar tejidos o órganos dañados o enfermos.",
        srcWebp: Cell2Webp,
        srcPng: Cell2Png,
        srcJpg: Cell2Jpg, 
        altImg: "Medicina regenerativa", 
        date: '',
        author: '',
        fullText: ``
    }, */
]

export default blog;