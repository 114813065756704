<template>
    <section id="treatments">
        <SectionName text="Procedimientos" class="d-flex justify-content-center" />
        <DecorationCurveDouble class="decoration-curve" v-if="decorationCurve" />
        <div :class="{'cards-treatments': decorationCurve}">
            <div class="cards-treatments-container" :class="{'no-decoration': !decorationCurve}">
                <CardProcedure class="card-item" 
                :background=background
                :icon="Run" 
                altIcon="Ícono de célula" 
                title="Bloqueos Nerviosos"
                >
                </CardProcedure>

                <CardProcedure class="card-item" 
                :background=background
                :icon="Blood" 
                altIcon="Ícono de plasma" 
                title="Medicina Regenerativa"
                >
                </CardProcedure>
            </div>
            <div class="cards-treatments-container">
                <CardProcedure class="card-item" 
                :background=background
                :icon="Health" 
                altIcon="Ícono de jeringa" 
                title="Medicamentos"
                >
                </CardProcedure>

                <CardProcedure class="card-item" 
                :background=background
                :icon="Syringe" 
                altIcon="Ícono de maletín" 
                title="Infiltraciones"
                >
                </CardProcedure>
            </div>
        </div>
    </section>
</template>

<script>
import SectionName from '@/components/SectionName.vue';
import DecorationCurveDouble from '@/components/DecorationCurveDouble.vue';
import CardProcedure from '@/components/CardProcedure.vue';
import Run from '@/assets/img/icons/webp/movilidad.webp';
import Health from '@/assets/img/icons/webp/salud.webp';
import Syringe from '@/assets/img/icons/webp/jeringa.webp';
import Blood from '@/assets/img/icons/webp/sangre.webp';

export default {
    name: 'ProceduresSection',
    components: {
        SectionName,
        DecorationCurveDouble,
        CardProcedure,
    },
    props: {
        decorationCurve: {
            type: Boolean,
            default: true,
        },
        background: {
            type: Boolean,
        }
    },
    data() {
        return {
            Run,
            Health,
            Syringe,
            Blood,
        }
    },
}
</script>

<style scoped>
#treatments{
    margin-top: 4rem;
}
.decoration-curve{
    position: relative;
    top: -310px;
    display: flex;
    justify-content: center;
}
.cards-treatments{
    margin-top: -200px;
}
ul.card-description{
    list-style-type: "- ";
    font-weight: 500;
    font-size: 0.95rem;
    line-height: 1.5rem;
    padding-left: 0.9rem;
    margin-bottom: 0;
}
.cards-treatments-container{
    margin-bottom: 1rem;
    min-width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}
.cards-treatments-container:first-of-type{
    margin-top: 3rem;
}
.card-item{
    flex: 1 0 100% ;
}
.no-decoration{
    margin-top: 20px;
}
/* RESPONSIVE */

@media (min-width:650px) {
    .cards-treatments{
        margin-top: -300px;
    }
}

@media (min-width:992px) {
    .card-item{
        flex: 0 0 calc(50% - 10px);
    }
    .cards-treatments{
        margin-top: -350px;
    }
    .cards-treatments-container:first-of-type{
        margin-top: 1rem;
    }
    .no-decoration{
        padding-top: 2.5rem;
    }
}

@media (min-width:1200px) {
    .card-item{
        flex: 0 0 calc(33.33% - 13px);
    }
    .cards-treatments-container:last-of-type{
        justify-content: flex-end;
    }
    .cards-treatments{
        margin-top: -400px;
    }
}
@media (min-width:1300px) {
    .cards-treatments{
        margin-top: -500px;
    }
}
</style>