<template>
    <section id="team" class="team-slider">
            <div id="full" class="team-slider__wrp swiper-wrapper">
                <!-- PRESENTACIÓN -->
                <div id="presentation" class="team-slider__item swiper-slide">
                    <div id="info-presentation" class="cont">
                        <div class="d-flex flex-column">
                            <div id="first-logo" class="">
                                <a href="/home.html">
                                    <picture>
                                        <source :srcset=LogoWebp type="image/webp">
                                        <source :srcset=LogoPng type="image/png">
                                        <img :src=LogoWebp alt="Logo Novocel - Medicina regenerativa">
                                    </picture>
                                </a>
                            </div>
                            <div id="presentation-text">
                                <h1 class="mb-0 mt-0 pt-0"> Somos </h1>
                                <h1 class="mt-0 mb-1 extrabold">Clínica Novocel</h1>
                                <h1 class="mt-0 pt-2 light">
                                    La primera clínica traumatológica de Viña del Mar dedicada exclusivamente a tratamientos con células madre para la artrosis
                                </h1>
                                <div class="optional-cont">
                                    <p class="optional">Traumatología y medicina regenerativa</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- EQUIPO MÉDICO -->
                <!-- MIEMBRO 1 -->
                <div id="team1" class="team-slider__item swiper-slide">
                    <div class="team-slider__img">
                        <picture>
                            <source :srcset=HumbertoWebp type="image/webp">
                            <source :srcset=HumbertoPng type="image/png">
                            <img :src=HumbertoWebp alt="Dr. Humberto Verdugo Marchese">
                        </picture>
                    </div>
                    <div class="team-slider__content">
                        <h6 class="text-uppercase team-slider__code">Director Médico</h6>
                        <div class="team-slider__title">
                            <h3 class="bold">Dr. Humberto Verdugo Marchese</h3>
                        </div>
                        <div class="team-slider__text">
                            <p>
                                Médico cirujano de la Universidad de Valparaíso, especialista en
                                Traumatología y Ortopedia. Fundador y director médico del centro médico Sports MD y
                                Clínica Novocel.
                            </p>
                        </div>
                        <a href="https://s3.philaxmed.cl/ReservaOnline.html?mc=Cl%C3%ADnicaNovocel#_" class="team-slider__button" target="_blank">Agenda tu hora aquí</a>
                    </div>
                </div>

                <!-- MIEMBRO 2 -->
                <div id="team2" class="team-slider__item swiper-slide">
                    <div class="team-slider__img">
                        <picture>
                            <source :srcset=HectorWebp type="image/webp">
                            <source :srcset=HectorPng type="image/png">
                            <img :src=HectorWebp alt="Dr. Héctor Flores">
                        </picture>
                    </div>
                    <div class="team-slider__content">
                        <h6 class="text-uppercase team-slider__code">Médico traumatólogo</h6>
                        <div class="team-slider__title">
                            <h3 class="bold">Dr. Héctor Flores</h3>
                        </div>
                        <div class="team-slider__text">
                            <p>
                                Médico cirujano especialista en traumatología, ortopedia, y oncología ortopédica.
                            </p>
                        </div>
                        <a href="https://s3.philaxmed.cl/ReservaOnline.html?mc=Cl%C3%ADnicaNovocel#_" class="team-slider__button" target="_blank">Agenda tu hora aquí</a>
                    </div>
                </div>

                <!-- MIEMBRO 3 -->
                <div id="team3" class="team-slider__item swiper-slide">
                    <div class="team-slider__img">
                        <picture>
                            <source :srcset=FernandoWebp type="image/webp">
                            <source :srcset=FernandoPng type="image/png">
                            <img :src=FernandoWebp alt="Dr. Fernando Tapia">
                        </picture>
                    </div>
                    <div class="team-slider__content">
                        <h6 class="text-uppercase team-slider__code">Médico especialista en dolor crónico</h6>
                        <div class="team-slider__title">
                            <h3 class="bold">Dr. Fernando Tapia</h3>
                        </div>
                        <div class="team-slider__text">
                            <p>
                                Médico cirujano de la Universidad de Valparaíso y cirujano plástico miembro de importantes sociedades internacionales. 
                                Director del área de medicina estética de Clínica Novocel.
                            </p>
                        </div>
                        <a href="https://s3.philaxmed.cl/ReservaOnline.html?mc=Cl%C3%ADnicaNovocel#_" class="team-slider__button" target="_blank">Agenda tu hora aquí</a>
                    </div>
                </div>

                <!-- MIEMBRO 4 -->
                <div id="team4" class="team-slider__item swiper-slide">
                    <div class="team-slider__img">
                        <picture>
                            <source :srcset=CinthiaWebp type="image/webp">
                            <source :srcset=CinthiaPng type="image/png">
                            <img :src=CinthiaWebp alt="Ing. Cinthia Briceño Rosas">
                        </picture>
                    </div>
                    <div class="team-slider__content">
                        <h6 class="text-uppercase team-slider__code">Directora Científica</h6>
                        <div class="team-slider__title">
                            <h3 class="bold">Ing. Cinthia Briceño Rosas</h3>
                        </div>
                        <div class="team-slider__text">
                            <p>
                                Ingeniera en biotecnología de la Universidad Andrés Bello.
                                Especialista en medicina regenerativa y proyectos de investigación. Ex-miembro
                                del comité científico de la Asociación Chilena de Ingeniería Tisular y Ondas de
                                Choque (ACHITOC). Fundador y director científico de Neobimed y Clínica Novocel.
                            </p>
                        </div>
                        <router-link to='/blog/post/1' class="team-slider__button" aria-label="Ver Unidad Científica">Ver Unidad Científica</router-link>
                    </div>
                </div>
            
                <!-- MIEMBRO 5 -->
                <!-- <div id="team5" class="team-slider__item swiper-slide">
                    <div class="team-slider__img">
                        <picture>
                            <source :srcset=RolichWebp type="image/webp">
                            <source :srcset=RolichPng type="image/png">
                            <img :src=RolichWebp alt="Dr. Rolich Escobar">
                        </picture>
                    </div>
                    <div class="team-slider__content">
                        <h6 class="text-uppercase team-slider__code">Cirujano Plástico y Estético</h6>
                        <div class="team-slider__title">
                            <h3 class="bold">Dr. Rolich Escobar</h3>
                        </div>
                        <div class="team-slider__text">
                            <p>
                                Médico cirujano de la Universidad de Valparaíso y cirujano plástico miembro de importantes sociedades internacionales. Director del área de medicina estética de Clínica Novocel.
                            </p>
                        </div>
                        <a href="https://s3.philaxmed.cl/ReservaOnline.html?mc=Cl%C3%ADnicaNovocel#_" class="team-slider__button" target="_blank">Agenda tu hora aquí</a>
                    </div>
                </div> -->
            </div>
            <div class="team-slider__pagination"></div>            
        </section>
</template>

<script>
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';
import LogoWebp from '../../assets/img/logos/web/isotipo-color.webp';
import LogoPng from '../../assets/img/logos/png/isotipo-color.png';
import HumbertoWebp from '../../assets/img/team/webp/humberto-web-hq-900.webp';
import HumbertoPng from '../../assets/img/team/png/humberto-web-hq-900.png';
import CinthiaWebp from '../../assets/img/team/webp/cinthia-web-hq-900.webp';
import CinthiaPng from '../../assets/img/team/png/cinthia-web-hq-900.png';
/* import HansWebp from '../../assets/img/team/webp/hans-web-hq-900.webp';
import HansPng from '../../assets/img/team/png/hans-web-hq-900.png'; */
import HectorWebp from '../../assets/img/team/webp/hector-web-hq-900.webp';
import HectorPng from '../../assets/img/team/png/hector-web-hq-900.png';
/* import RolichWebp from '../../assets/img/team/webp/rolich-web-hq-900.webp';
import RolichPng from '../../assets/img/team/png/rolich-web-hq-900.png'; */
import FernandoWebp from '../../assets/img/team/webp/fernando-web-hq-900.webp';
import FernandoPng from '../../assets/img/team/png/fernando-web-hq-900.png';

export default {
    name: 'SectionAnimatedPresentation',
    data() {
        return {
            LogoWebp, LogoPng, HumbertoWebp, HumbertoPng, CinthiaWebp, CinthiaPng, /* HansWebp, HansPng, */ HectorWebp, HectorPng /* RolichWebp, RolichPng, */, FernandoWebp, FernandoPng,
        };
    },
    mounted() {
        this.swiper = new Swiper('.team-slider', {
            spaceBetween: 30,
            effect: 'fade',
            loop: true,
            autoplay: {
                delay: 5500,
                disableOnInteraction: false,
            },
            pagination: {
                el: '.team-slider__pagination',
                clickable: true,
            },
        });
    },
    beforeUnmount() {
        if (this.swiper) {
            this.swiper.destroy();
        }
    },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* PRESENTATION */
/* h1{
    font-family: "Lato", sans-serif !important;
} */
#presentation {
    padding: 0;
    width: 100%;
    min-height: calc(100vh - 80px);
    background-image: url("../../assets/img/clinica/consulta2-opacity.jpg");
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    #first-logo {
        width: 100%;
        max-width: 1220px;
        display: flex;
        justify-content: flex-end;
        img {
            height: 80px;
            max-height: 120px;
            margin-bottom: 15px; 
        }
    }

    img#photo-presentation {
        width: 100%;
        object-fit: cover;
        object-position: top center;
        opacity: 0.4;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        min-height: calc(100vh - 80px);
    }

    div.cont {
        max-width: 1480px;
        padding: 0 5vw;
        position: relative;
        z-index: 3;
        align-items: center;
        display: flex;

        div.d-flex {
            div#presentation-text {
                h1 {
                    color: #ffffff;
                    text-align: center;
                    font-size: 26px;
                    padding-top: 30px;
                    margin-bottom: 0;
                    line-height: 32.2px;
                }
            }
        }
    }
}

#presentation {
    div.cont {
        height: 100%;
        min-height: calc(100vh);
        padding-top: 50px;

        div.d-flex {
            div#presentation-text {
                max-width: 100%;

                h1 {
                    margin-top: 30px;
                    text-align: left;
                    font-size: 42px;
                    line-height: 38.5px;
                }

                h1.extrabold {
                    font-size: 58px;
                    line-height: 62px;
                    padding-top: 20px;
                }

                h1.light {
                    font-size: 27.2px;
                    line-height: 38.5px;
                    margin-bottom: 40px;
                }

                div.optional-cont {
                    p.optional {
                        color: #3c3e6b;
                        text-transform: uppercase;
                        font-size: 14px;
                        margin: 15px 0;
                    }
                }
            }
        }
    }

    img#photo-presentation {
        min-height: calc(100vh);
    }
}

#presentation.team-slider__item {
    background-image: none;

    div.cont {
        div.d-flex {
            div#presentation-text {
                h1 {
                    color: #3c3e6b;
                }
            }
        }
    }
}



/* TEAM_HOME */
/* MOBILE LAST */
.team-slider {
    width: 95lvw;
    margin: auto;
    overflow: hidden;
    height: calc(80lvh - 80px);
    border-radius: 25px;
    transition: all 0.3s;
    position: relative;
    top: 15lvh;
    margin-bottom: 25lvh;
}

/* #team2,
#team4 {
    background-image: url("/images/clinica/consulta2-opacity.jpg");
}

#team1,
#team3 {
    background-image: url("/images/clinica/recepcion-opacity.jpg");
}

#team5 {
    background-image: url("/images/clinica/espera-opacity.jpg");
} */

.team-slider__item.swiper-slide-active .team-slider__img img {
    opacity: 1;
    transition-delay: 0.3s;
}

.team-slider__item.swiper-slide-active .team-slider__content>* {
    opacity: 1;
    transform: none;
}

.team-slider__item.swiper-slide-active .team-slider__content>*:nth-child(1) {
    transition-delay: 0.3s;
}

.team-slider__item.swiper-slide-active .team-slider__content>*:nth-child(2) {
    transition-delay: 0.4s;
}

.team-slider__item.swiper-slide-active .team-slider__content>*:nth-child(3) {
    transition-delay: 0.5s;
}

.team-slider__item.swiper-slide-active .team-slider__content>*:nth-child(4) {
    transition-delay: 0.6s;
}

.team-slider__item.swiper-slide-active .team-slider__content>*:nth-child(5) {
    transition-delay: 0.7s;
}

.team-slider__item.swiper-slide-active .team-slider__content>*:nth-child(6) {
    transition-delay: 0.8s;
}

.team-slider__item.swiper-slide-active .team-slider__content>*:nth-child(7) {
    transition-delay: 0.9s;
}

.team-slider__item.swiper-slide-active .team-slider__content>*:nth-child(8) {
    transition-delay: 1s;
}

.team-slider__item.swiper-slide-active .team-slider__content>*:nth-child(9) {
    transition-delay: 1.1s;
}

.team-slider__item.swiper-slide-active .team-slider__content>*:nth-child(10) {
    transition-delay: 1.2s;
}

.team-slider__item.swiper-slide-active .team-slider__content>*:nth-child(11) {
    transition-delay: 1.3s;
}

.team-slider__item.swiper-slide-active .team-slider__content>*:nth-child(12) {
    transition-delay: 1.4s;
}

.team-slider__item.swiper-slide-active .team-slider__content>*:nth-child(13) {
    transition-delay: 1.5s;
}

.team-slider__item.swiper-slide-active .team-slider__content>*:nth-child(14) {
    transition-delay: 1.6s;
}

.team-slider__item.swiper-slide-active .team-slider__content>*:nth-child(15) {
    transition-delay: 1.7s;
}

.team-slider__img {
    flex-shrink: 0;
    overflow: hidden;
}

.team-slider__img:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0.8;
}

.team-slider__img img {
    width: 100%;
    object-fit: cover;
    display: block;
    opacity: 0;
    transition: all 0.3s;
}

.team-slider__content {
    padding-right: 25px;
    padding-right: 70px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
}

.team-slider__content>* {
    opacity: 0;
    transform: translateY(25px);
    transition: all 0.4s;
}

.team-slider__code {
    margin-bottom: 10px;
    display: block;
    font-weight: 700;
    text-transform: uppercase;
}

.team-slider__title {
    margin-bottom: 5px;

    h3 {
        font-weight: 800;
    }
}

.team-slider__text {
    line-height: 2.1em;
    margin: 0 0 10px 0;

    p {
        font-weight: 500;
    }
}

.team-slider__button {
    display: inline-flex;
    text-decoration: none;
    justify-content: center;
    text-align: center;
    width: max-content;
    padding: 8px 20px;
    border-radius: 30px;
    color: #ffffff;
    background-image: linear-gradient(120deg, #3c3e6b 42%, #3c3e6b 100%);
}

.team-slider__button:hover {
    color: #ffffff;
    background-image: linear-gradient(120deDg, rgb(77, 96, 152) 42%, rgb(77, 96, 152) 100%);
}

.team-slider .swiper-container-horizontal>.swiper-pagination-bullets,
.team-slider .swiper-pagination-custom,
.team-slider .swiper-pagination-fraction {
    bottom: 10px;
    left: 0;
    width: 100%;
    position: relative;
    z-index: 100;
}

.team-slider__pagination {
    position: absolute;
    z-index: 1;
    right: 20px;
    width: 11px !important;
    text-align: center;
    left: auto !important;
    top: 50%;
    bottom: auto !important;
    transform: translateY(-50%);
}

.team-slider__pagination.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 8px 0;
}

.team-slider__pagination .swiper-pagination-bullet {
    width: 11px;
    height: 11px;
    display: block;
    border-radius: 10px;
    background: #062744;
    opacity: 0.2;
    transition: all 0.3s;
}

.team-slider__pagination .swiper-pagination-bullet-active {
    opacity: 1;
    height: 30px;
    box-shadow: 0px 0px 20px rgba(252, 56, 56, 0.3);
    background-image: linear-gradient(180deg, #3c3e6b 30%, #436aaf 100%);
}

.team-slider__wrp {
    height: 100lvh;
    margin: 0;
    margin-bottom: 80px;
}

/* FULL */

#team.team-slider {
    /* border-radius: 0; */
    position: absolute;
    top: -20px;
    left: -30px;
    width: 100svw;
    height: 100svh;
    min-height: 100svh;
}

@media (min-width:768px) {
    #team.team-slider {
        top: -30px;
        left: -45px;
    }
}

@media (min-width:992px) {
    #team.team-slider {
        top: -45px;
        left: -75px;
    }
}

.team-slider__item.swiper-slide-active {
    width: 100% !important;
}

.team-slider__item .team-slider__content {
    height: 100lvh;
    padding-top: 15lvh;
}

.team-slider__item .team-slider__img {
    height: 100lvh;
    padding-top: 128px; /* 10lvh; */
}

.team-slider__item.swiper-slide-active .team-slider__img img {
    height: 100lvh;
}

/* RESPONSIVE */

.team-slider__item {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: ' team-slider__content';
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
    border-radius: 0;
    height: 100lvh;
    align-items: flex-end;
}

.team-slider__img {
    grid-area: team-slider__img;
    display: none;
}

.team-slider__content {
    grid-area: team-slider__content;
    padding: 0 40px 0 18px;
}

#presentation.team-slider__item {
    grid-template-columns: 1fr;
}

#presentation {
    div.cont {
        height: 100%;
        min-height: calc(100vh);
        padding-top: 50px;

        div.d-flex {
            div#presentation-text {
                max-width: 100%;

                h1 {
                    margin-top: 30px;
                    text-align: left;
                    font-size: 42px;
                    line-height: 38.5px;
                }

                h1.extrabold {
                    font-size: 58px;
                    line-height: 62px;
                    padding-top: 20px;
                }

                h1.light {
                    font-size: 27.2px;
                    line-height: 38.5px;
                    margin-bottom: 40px;
                }

                div.optional-cont {
                    p.optional {
                        color: #3c3e6b;
                        text-transform: uppercase;
                        font-size: 14px;
                        margin: 15px 0;
                    }
                }
            }
        }
    }

    img#photo-presentation {
        min-height: calc(100vh);
    }
}

#presentation.team-slider__item {
    background-image: none;

    div.cont {
        div.d-flex {
            div#presentation-text {
                h1 {
                    color: #3c3e6b;
                }
            }
        }
    }
}


.team-slider__code {
    font-size: 1rem;
}

.team-slider__title {
    h3 {
        font-size: 2.2rem;
    }
}

.team-slider__text {
    line-height: 1.8rem;

    p {
        font-size: 1.1rem;
    }
}

.team-slider__button {
    font-size: 1rem;
}

@media screen and (min-width: 768px) {
    main#index {
        #presentation {
            div#info-presentation {
                div.d-flex {
                    flex-direction: row !important;
                }
            }
        }
    }
    .team-slider__content {
        padding: 25px 70px;
    }

}

@media screen and (min-width: 768px) and (min-height: 440px) {

    #presentation {
        div.cont {
            div.d-flex {
                margin-top: 30px;

                div#presentation-text {
                    h1 {
                        font-size: 68px;
                        line-height: initial;
                    }

                    h1.extrabold {
                        font-size: 78px;
                        line-height: 62px;
                        padding-bottom: 20px;
                    }

                    h1.light {
                        font-size: 37.2px;
                        line-height: 47.5px;
                    }
                }
            }
        }
    }

}

@media screen and (min-width: 1024px) {

    #presentation {
        div.cont {
            width: 90vw;
            padding: 0;

            div.d-flex {
                div#presentation-text {
                    h1 {
                        font-size: 46px;
                    }
                }
            }
        }

        #first-logo {
            width: 90vw;
            display: flex;
            justify-content: flex-start;
            position: initial;
            margin: auto;
            img{
                height: 120px;
            }
        }
    }

    .team-slider__item {
        grid-template-columns: 1fr 1fr;
        grid-template-areas: ' team-slider__img team-slider__content';

        .team-slider__content {
            padding: 120px 70px 25px;
        }
    }

    .team-slider__img {
        display: initial;
    }


    .team-slider__code {
        font-size: 1.2rem;
    }

    .team-slider__title {
        h3 {
            font-size: 2.7rem;
        }
    }

    .team-slider__text {
        line-height: 1.8rem;

        p {
            font-size: 1.1rem;
        }
    }

    .team-slider__button {
        font-size: 1rem;
    }
}

@media screen and (min-width: 1200px) {

    #presentation {
        div.cont {
            div.d-flex {
                div#presentation-text {
                    max-width: 1220px;

                    h1 {
                        font-size: 78px;
                        line-height: initial;
                    }

                    h1.extrabold {
                        font-size: 88px;
                    }

                    h1.light {
                        font-size: 47.2px;
                        line-height: 57.5px;
                    }

                    div.optional-cont {
                        p.optional {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

}


@media screen and (min-width: 1600px) {

    .team-slider__code {
        font-size: 1.5rem;
    }

    .team-slider__title {
        h3 {
            font-size: 3rem;
        }
    }

    .team-slider__text {
        line-height: 2.4rem;

        p {
            font-size: 1.3rem;
        }
    }

}

/* MEDIA QUERIES */
@media screen and (min-width: 580px) {
    #presentation {
        #first-logo {
            width: fit-content;
            display: flex;
            justify-content: flex-start;
            img {
                margin-bottom: 30px;
                height: 100px;
            }
        }
    }
}

@media screen and (min-width: 768px) and (min-height: 440px) {

    /* PRESENTATION / FORM */
    #presentation {
        div.cont {
            display: flex;
            justify-content: space-between;
            margin: auto;
            min-height: calc(100vh - 80px);

            div.d-flex {
                flex-direction: row;
                div#presentation-text {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-evenly;
                    max-width: 840px;
                    padding-right: 20px;

                    h1 {
                        text-align: left;
                        font-size: 42px;
                        line-height: initial;
                        padding-right: 20px;
                    }

                    div.optional-cont {
                        p.optional {
                            text-transform: uppercase;
                            font-size: 14px;
                            margin: 15px 0;
                        }
                    }
                }
            }
        }
    }


    #presentation {
        div.cont {
            div.d-flex {
                margin-top: 30px;

                div#presentation-text {
                    h1 {
                        font-size: 58px;
                        line-height: initial;
                    }

                    h1.extrabold {
                        font-size: 68px;
                        line-height: 62px;
                        padding-bottom: 10px;
                    }

                    h1.light {
                        font-size: 34px;
                        line-height: 47.5px;
                        margin-bottom: 20px
                    }
                }
            }
        }
    }

}

@media screen and (min-width: 1024px) {

    /* PRESENTATION / FORM */
    #presentation {
        div.cont {
            /* padding: calc(5vw + 64px) 25px 50px; */
            /* padding: 5vw 25px 50px; */
            padding: 25px 25px 0px;
            div.d-flex {
                div#presentation-text {
                    h1 {
                        font-size: 46px;
                    }
                }
            }
        }
        #first-logo {
            width: 90vw;
            display: flex;
            justify-content: flex-start;
            position: initial;
            margin: auto;
            img {
                margin-bottom: 20px;
                height: 100px;
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    #presentation {
        div.cont {
            div.d-flex {
                div#presentation-text {
                    max-width: 840px;
                    h1 {
                        font-size: 54px;
                        padding-top: 10px;
                    }
                    div.optional-cont {
                        p.optional {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1600px) {

    #presentation {
        div.cont {
            div.d-flex {
                div#presentation-text {
                    max-width: 1220px;

                    h1 {
                        font-size: 78px;
                        line-height: initial;
                    }

                    h1.extrabold {
                        font-size: 88px;
                        padding-bottom: 30px;
                    }

                    h1.light {
                        font-size: 45px;
                        line-height: 57.5px;
                        padding-bottom: 20px;
                    }

                    div.optional-cont {
                        p.optional {
                            font-size: 20px;
                        }
                    }
                }

            }
        }
    }

}

/* ARREGLOS A NUEVO DISEÑO */
.team-slider__item {
    border-radius: 1.5rem;
    background-image: url("../../assets/img/clinica/consulta2-opacity.jpg") !important;
    background-size: cover;
    background-repeat: no-repeat;
}

p.optional.first{
    color: #3c3e6b!important;
}
h1{
    font-weight: 300;
}
h1.light{
    font-weight: 200;
    letter-spacing: 1px;
}
h1.extrabold{
    font-weight: 800;
}
.we-are{
    position: relative;
    bottom: -10px;
}
div.optional-cont {
    border-top: #3c3e6b 1px solid !important;
    border-bottom: #3c3e6b 1px solid !important;
    max-width: max-content;
}
@media screen and (max-height: 740px) {
    #presentation { 
        #first-logo {
            display: none;
        }
    }
}
@media screen and (max-width: 580px) {
    .team-slider__item.swiper-slide .team-slider__content,
    .team-slider__item.swiper-slide .cont div.d-flex.flex-column{
        position: relative;
        top: -45px;
        transform: scale(0.9);
    }
}
</style>