<template>
    <main>
        <SectionIntroTitle 
        sectionName="Blog"
        alignCenter="true"
        ppalText="Conoce nuestros"
        secondText="últimos posteos."
        />
        
        <WhiteBackground backColor="white">
            <section id="blog-info">
                <!-- <p class="description col-12 col-lg-10 col-xl-7"></p> -->
                <div class="filters col-12 col-lg-10 col-xl-9">
                    <ButtonFilter class="button-filter" id="news-card" text="Todas" @click="filterSection('news-card')"/>
                    <ButtonFilter class="button-filter" id="stem-cells" text="Células Madre" @click="filterSection('stem-cells')"/>
                    <!-- <ButtonFilter class="button-filter" id="regenerative-medicine" text="Medicina Regenerativa" @click="filterSection('regenerative-medicine')"/> -->
                    <!-- <ButtonFilter class="button-filter" id="hyaluronic-acid" text="Ácido Hialurónico" @click="filterSection('hyaluronic-acid')"/> -->
                    <!-- <ButtonFilter class="button-filter" id="kinesiology" text="Kinesiología" @click="filterSection('kinesiology')"/> -->
                </div>
                <div class="news-card-container gap-3 mt-5">
                    <template v-for="item in reorderedItems" :key="item.id">
                        <CardNew
                        class="news-card"
                        :class="item.classSection"
                        :srcWebp="item.srcWebp"
                        :srcWebp320="item.srcWebp320"
                        :srcWebp480="item.srcWebp480"
                        :srcWebp768="item.srcWebp768"
                        :srcWebp1080="item.srcWebp1080"  
                        :altImg="item.altImg"
                        :section="item.section"
                        :title="item.title"
                        :description="item.description"
                        :link="'/blog/post/' + item.id"
                        />
                    </template>
                </div>
            </section>
        </WhiteBackground>
        
    </main>
    <FooterBar />
</template>

<script>
import FooterBar from '../BasicComponents/FooterBar.vue';
import SectionIntroTitle from '@/components/SectionIntroTitle.vue';
import WhiteBackground from '@/components/WhiteBackground.vue';
import ButtonFilter from '@/components/ButtonFilter.vue';
import CardNew from '@/components/CardNew.vue';
import BlogNews from './blogNews.js'

export default {
    name: 'BlogPage',
    components: {
        FooterBar,
        SectionIntroTitle,
        WhiteBackground,
        ButtonFilter,
        CardNew,
    },
    data() {
        return {
            BlogNews : BlogNews,
        }
    },
    computed: {
        reorderedItems() {
            return this.BlogNews.slice().reverse();
        },
    },
    methods: {
        filterSection(sectionName) {
            let buttonsFilter = Array.from(document.getElementsByClassName("button-filter"));
            buttonsFilter.forEach(element => {
                element.classList.remove('selected');
            });

            let buttonId = document.getElementById(sectionName);
            buttonId.classList.add('selected');
            
            let allNews = Array.from(document.getElementsByClassName("news-card"));
            allNews.forEach(element => {
                element.style.display="none";
            });
            
            let newsClass = Array.from(document.getElementsByClassName(sectionName));
            newsClass.forEach(element => {
                element.style.display="flex";
            });            
        },
    }
}
</script>

<style scoped>
#blog-info{
    padding: 3rem 0;
    max-width: 1500px;
    margin: auto;
    margin-top: 3.5rem;
}
.description{
    text-align: center;
    margin: auto;
}
.filters{
    margin: 1.5rem auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.news-card-container{
    display: grid;
    grid-template-columns: 1fr;
}
/* RESPONSIVE */
@media (min-width:768px) {
    .news-card-container{
        grid-template-columns: 1fr 1fr;
    }
}
@media (min-width:992px) {
    .news-card-container{
        grid-template-columns: 1fr 1fr 1fr;
    }
}
</style>