import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import './assets/css/novocel.css'
import { createApp } from 'vue'

/*---------  PARA USAR EN HOME -----------*/
/*-- COMENTAR SI SE QUIERE USAR LANDING --*/
import router from "@/router/index.js";  
import App from './App.vue'; 

/*----------  PARA USAR EN LANDING ------------*/
/*------ COMENTAR SI SE QUIERE USAR HOME ------*/
/* import router from "@/router/indexLanding.js"; 
import App from './LandingApp.vue';  */

const app = createApp(App);
app.use(router);
app.mount('#app');
export { app };